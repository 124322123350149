import React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { baseUrl, apiV6, useSendTrackerMutation } from "@api/apiV6";
import { trackClear } from "@reducers/trackerSlice";
import { clearAttempt } from "@reducers/attemptSlice";
import { clearArticles } from "@reducers/articlesSlice";

import { logout } from "@reducers/authSlice";

import { Div, Button, HDiv } from "@components/Generics.react";
import AuthLayout from "@layouts/AuthLayout.react";

export default function Logout(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const auth = useSelector((state) => state.auth);
    const tracker = useSelector((state) => state.tracker);
    const [sendTracker] = useSendTrackerMutation();

    const serverLogout = async () => {
        const response = await axios({
            method: "post",
            url: `${baseUrl}api/v3/auth/signOut`,
            headers: { "access-token": auth.token },
        });
        return response;
    }

    const tryLogout = async () => {
        try {
            await sendTracker({ trackers: tracker }).unwrap();
            await dispatch(trackClear());
            await dispatch(clearAttempt());
            await dispatch(clearArticles());
            await dispatch(apiV6.util.resetApiState());
            await dispatch(logout());
            await serverLogout()
        } catch (e) {
            console.log(e);
            dispatch(apiV6.util.resetApiState());
            dispatch(logout());
            serverLogout()
        }
    };

    // ========================= Render Function =================================
    return (
        <AuthLayout title={"Logout"} team={auth.user.team}>
            <Div fluid white fullHt>
                <Div massive altText fluid center-align gutter>
                    {t("auth.logout.header")}
                </Div>
                <HDiv big trench content={t("auth.logout.question")} />
                <hr />
                <br />
                <Div clear>
                    <Button
                        content={t("components.no")}
                        onClick={() => navigate(-1)}
                    />
                    <Button
                        danger={+true}
                        content={t("components.yes")}
                        onClick={tryLogout}
                    />
                </Div>
            </Div>
        </AuthLayout>
    );
}
