import React from "react";
import { Div, Button, Image } from "@components/Generics.react";
import { Card, Message } from "semantic-ui-react";

export default function BannerCard(props) {
    const { ads, adsSuccess } = props;

    // If the ads data is not successfully fetched or is undefined
    if (!adsSuccess || !Array.isArray(ads) || ads.length === 0) {
        return (
            <Div fluid>
                <Card fluid>
                    <Message warning>No Ads available.</Message>
                </Card>
            </Div>
        );
    }

    const sortedAds = [...ads].sort((a, b) => b.priority - a.priority);

    return (
        <Div fluid>
            <Card.Group itemsPerRow={1}>
                {sortedAds.map((ads) => (
                    <Card key={ads.id}>
                        <Image src={ads.image} wrapped ui={false} />
                        <Card.Content>
                            <Card.Header>{ads.name}</Card.Header>
                            <Card.Description>{ads.description}</Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <Button fluid primary href={ads.link}>
                                {ads.button_text || "Click Here"}
                            </Button>
                        </Card.Content>
                    </Card>
                ))}
            </Card.Group>
        </Div>
    );
};